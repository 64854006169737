.main_banner {
    position: relative;
    margin-top: 4%;
}

.btn_shop img {
    width: 100%;
    height: 250px;
}

.btn_shop button {
    position: absolute;
    top: 80%;
    left: 13%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    background-color: #797776;
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    width: 12%;
}