.main_footer {
    padding: 2% 7% 2%;
    background-color: #bdbdbd; 
    color: #333;
}

.main_footer h5 {
    font-size: 15px;
}

.top_footer {
    display: flex;
    justify-content: space-between;
}

.left_footer {
    line-height: 1.5;
}

.left_footer p {
    padding-bottom: 10px;
    font-size: 12px;
}

.email_footer {
    display: flex;
    align-items: center;
}

.email_footer input {
    padding: 10px 20px;
    border-radius: 30px;
    border: 1px solid rgb(51, 51, 51);
    outline: none;
    margin-right: 10px;
    width: 350px;
    height: 40px;
    font-size: 15px;
    font-weight: 500;
}

.email_footer button {
    padding: 10px 20px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    border-radius: 30px;
    border: none;
    cursor: pointer;
    width: 150px;
    height: 40px;
    font-size: 15px;
    font-weight: 500;
}

.right_footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: baseline;
    margin-right: 140px;
    gap: 70px;
    line-height: 1.5;
}

.right_footer p {
    font-size: 15px;
    font-weight: 400;
}

.bottom_footer {
    display: flex;
    margin-top: 50px;
    gap: 15px;
    font-size: 12px;
    font-weight: 500;
}