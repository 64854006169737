.gift_necklaces {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
}

.title_for {
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 500;
    padding-bottom: 10px;
}

.img_gift_necklaces {
    height: 500px;
    width: 630px;
}

.img_for {
    width: 100%;
    height: 100%;
    border: none;
    background-color: rgb(148, 147, 147);
    object-fit: fill;
    border-radius: 5px;
}

/* Điều chỉnh cho màn hình nhỏ (điện thoại) */
@media (max-width: 767px) {
    .gift_necklaces {
        display: block;
        margin-bottom: 20px;
        margin-top: 40px;
    }
    
    .img_gift_necklaces {
        width: 100%;
        height: 50%;
    }

    .title_for {
        font-size: 20px !important;
    }

    .gift_for_her {
        margin-top: 40px;
    }
}
