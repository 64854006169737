* {
    margin-bottom: 0 !important;
}

.supper-slider {
    width: 100%;
    max-width: 100%;
    position: relative;
}
  
.slider-item {
    position: relative;
}
  
.slider-image {
    width: 100vw;
    height: auto;
    display: block;
    object-fit: cover;
}
  
.slider-text-overlay {
    position: absolute;
    top: 3%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 10;
}
  
.slider-text-overlay h2 {
    margin: 0;
    font-size: 20px;
    overflow: hidden;
    text-transform: uppercase;
}

.slider-text-button-overlay {
    position: absolute;
    z-index: 10;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.slider-text-button-overlay p {
    text-transform: uppercase;
    color: black;
    font-size: 35px;
    font-weight: 500;
}

.slider-text-button-overlay button {
    border-radius: 50px;
    padding: 10px 50px;
    border: none;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
    cursor: pointer;
}
  
/* Media queries cho màn hình nhỏ (điện thoại) */
@media (max-width: 767px) {
    .slider-image {
        height: 70vh; /* Sử dụng height để làm ảnh cao hơn trên điện thoại */
        object-fit: cover; /* Giữ cho ảnh bao phủ khu vực hiển thị */
    }
    .slider-text-overlay h2 {
        font-size: 16px; /* Giảm kích thước chữ */
    }

    .slider-text-button-overlay p {
        font-size: 25px; /* Giảm kích thước text trên điện thoại */
    }

    .slider-text-button-overlay button {
        padding: 8px 30px; /* Giảm padding của button */
        font-size: 16px;   /* Giảm kích thước chữ của button */
    }
}

/* Media queries cho màn hình lớn (laptop và desktop) */
@media (min-width: 768px) {
    .slider-text-overlay h2 {
        font-size: 20px; /* Kích thước chữ lớn hơn cho màn hình lớn */
    }

    .slider-text-button-overlay p {
        font-size: 35px; /* Giữ nguyên kích thước chữ */
    }

    .slider-text-button-overlay button {
        padding: 10px 50px; /* Giữ padding cho button */
        font-size: 20px;    /* Giữ kích thước chữ cho button */
    }
}