.bracelet_and_keychains {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
    margin: 4% 0;
}

.title_for {
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 500;
    padding-bottom: 10px;
}

.img_bracelet_and_keychains {
    height: 500px;
    width: 630px;
}

.img_for {
    width: 100%;
    height: 100%;
    border: none;
    background-color: rgb(148, 147, 147);
    object-fit: fill;
}