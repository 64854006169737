.new_arrival {
    margin-top: 8%;
}

.title {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
}
  
.product {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 2fr)); /* Sử dụng Grid với cột động */
    grid-gap: 20px;
    margin-top: 20px;
}
  
.card_product {
    display: flex;
    justify-content: center;
}
  
.name_product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}
  
.icon_love {
    cursor: pointer;
    height: 30px;
    width: 30px;
    background: #928f5a9e;
    border-radius: 50%;
    font-size: 20px;
}
  
.price_and_addtocart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}
  
@media only screen and (max-width: 768px) {
    .title {
      font-size: 20px;
    }
  
    .product {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 15px;
    }
  
    .price_and_addtocart p {
      font-size: 14px;
    }
}
  
@media only screen and (max-width: 480px) {
    .title {
      font-size: 18px;
    }
  
    .product {
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  
    .price_and_addtocart p {
      font-size: 12px;
    }
}
  