* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: none;
}

.supper-app {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-app {
  width: 100vw;
}